import * as React from 'react'
import {inject, observer} from 'mobx-react'
import {Link} from 'react-router-dom'
import {Alert, Button, Form, Table} from 'react-bootstrap'
import AutosizeInput from 'react-input-autosize'

import { PlaylistStore } from 'stores/playlistStore'
import { ContentStore } from 'stores/contentStore'
import { AssetShowtimeStore } from 'stores/assetShowtimeStore'
import Playlist from 'stores/Playlist'
import Asset from 'stores/Asset'
import AssetShowtime from 'stores/AssetShowtime'
import AddAssetModal from './AddAssetModal'
import NotFound from 'modules/NotFound'
import ShowtimeRow from './ShowtimeRow'
import { computed } from 'mobx'
import { AlertStore } from '../../stores/alertStore'

const MIN_ASSET_SECONDS = 10

interface AssetDuration {
  second: number
  minute: number
}

interface AssetMap {
  [id: string]: Asset
}

interface Props {
  playlistStore?: PlaylistStore
  contentStore?: ContentStore
  assetShowtimeStore?: AssetShowtimeStore
  alertStore: AlertStore
  match: {
    params: {
      id: string
    }
    isExact?: boolean
    path?: string
    url?: string
  }
}

interface State {
  playlist: Playlist
  selectedAssets: AssetMap
  addingAssets: boolean
  error: boolean
  dragOver: number
  dragIndex: number
  eolClass: string
  titleValue: string
}

@inject('playlistStore', 'contentStore', 'assetShowtimeStore', 'alertStore')
@observer
class EditPlaylist extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      playlist: null,
      error: null,
      selectedAssets: {},
      addingAssets: false,
      dragOver: null,
      dragIndex: null,
      eolClass: '',
      titleValue: '',
    }
  }

  @computed
  get playlist() {
    return this.props.playlistStore.findById(this.props.match.params.id)
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData = () => {
    if (this.state.playlist) {
      this.setState({ error: false })
      return
    }

    const playlistID = this.props.match.params.id

    const playlistPromise: Promise<Playlist> = this.props.playlistStore.fetch(
      playlistID
    )
    const assetsPromise: Promise<
      Asset[]
      > = this.props.contentStore.fetchForPlaylist(playlistID)
    const showtimePromise: Promise<
      AssetShowtime[]
      > = this.props.assetShowtimeStore.fetchForPlaylist(playlistID)

    Promise.all([playlistPromise, assetsPromise, showtimePromise])
      .then((results) => {
        const playlist = results[0]
        this.setState({
          playlist,
          titleValue: playlist.title,
        })
      })
      .catch((data) => {
        this.setState({
          error: true,
        })
      })
  }

  selectMinute(showtime: AssetShowtime, event) {
    let val = event.target.value
    if (val.length > 2) {
      val = val.substring(0, 2)
    }

    val = Number(val)
    if (val < 1 && showtime.durationSeconds < MIN_ASSET_SECONDS)
      showtime.durationSeconds = MIN_ASSET_SECONDS

    const minute = val
    if (minute <= 60 && minute >= 0) {
      showtime.durationMinutes = minute
      showtime.update()
    }
  }

  selectSecond(showtime: AssetShowtime, event) {
    let val = event.target.value
    if (val.length > 2) {
      val = val.substring(0, 2)
    }

    val = Number(val)
    if (showtime.durationMinutes < 1 && val < MIN_ASSET_SECONDS)
      val = MIN_ASSET_SECONDS

    const second = val
    if (second <= 59 && second >= 0) {
      showtime.durationSeconds = second
      showtime.update()
    }
  }

  handleAddAsset = () => {
    this.setState({
      addingAssets: true,
    })
  }

  handleRemoveAsset = () => {
    const selected: AssetMap = this.state.selectedAssets

    for (const id in selected) {
      if (selected.hasOwnProperty(id)) {
        const asset: Asset = selected[id]
        this.playlist.removeAsset(asset)
      }
    }

    this.setState({
      selectedAssets: {},
    })
  }

  checkAsset = (asset: Asset) => {
    const selected: AssetMap = { ...this.state.selectedAssets }
    const id: string = asset.id

    if (id in selected) {
      delete selected[id]
    } else {
      selected[id] = asset
    }

    this.setState({
      selectedAssets: selected,
    })
  }

  allAssetsChecked = () => {
    const selectedAssets = this.state.selectedAssets
    if (this.state.playlist.assets.length === 0) {
      return false
    }

    return (
      Object.keys(selectedAssets).length === this.state.playlist.assets.length
    )
  }

  checkAll = () => {
    if (this.allAssetsChecked()) {
      this.setState({ selectedAssets: {} })
    } else {
      const assetsMap: AssetMap = this.state.playlist.assets.reduce(
        (map, asset) => {
          map[asset.id] = asset
          return map
        },
        {}
      )

      this.setState({
        selectedAssets: assetsMap,
      })
    }
  }

  handleEnabledChanged = (showtime: AssetShowtime) => {
    showtime.enabled = !showtime.enabled
    showtime.update()
  }

  onDrag = (assetIndex: number) => {
    // Asset being dragged

    this.setState({ dragIndex: assetIndex })
  }

  onDragOver = (assetIndex: number) => {
    // Asset being dragged over

    // If we are dragging over a different asset
    if (this.state.dragOver !== assetIndex) {
      this.setState({ dragOver: assetIndex })
    }
  }

  updatePlaylistName = () => {
    if (this.state.titleValue.trim() !== this.state.playlist.title) {
      const safeTitle =
        this.state.titleValue.trim().length > 0
          ? this.state.titleValue.trim()
          : this.state.playlist.title

      this.state.playlist.updateTitle(safeTitle).then((res) => {
        this.setState({
          titleValue: String(res),
        })
      })
    }
  }

  onChangePlaylistName = (e) => {
    this.setState({
      titleValue: e.target.value,
    })
  }

  onDrop = (assetIndex: number = null) => {

    // Asset being dropped on

    // Place at assetIndex, otherwise place at end of list
    this.state.playlist.moveShowTime(
      this.state.dragIndex,
      assetIndex !== null
        ? assetIndex
        : this.state.playlist.showtimesByIndex.length + 1
    )

    this.setState({
      dragIndex: null,
      dragOver: null,
      eolClass: '',
    })
  }

  onDragOverEndOfList = (event) => {
    event.preventDefault()

    if (this.state.dragOver) {
      this.setState({ dragOver: null })
    }

    this.setState({ eolClass: 'drag-n-drop' })
  }

  onDragLeaveEndOfList = (event) => {
    event.preventDefault()
    this.setState({ eolClass: '' })
  }

  render() {
    const playlist = this.state.playlist

    if (playlist) {
      const owner = this.playlist.owner

      return (
        <div className="edit-playlist col-xs-12">
          <div id="page-title-wrapper">
            <span className="page-title">
              <Link to="/playlists">Playlists</Link>
            </span>{' '}
            <span className="page-breadcrumb">{'>'}</span>
            <AutosizeInput
              name="playlist-name"
              className="playlist-name"
              value={this.state.titleValue}
              style={{ fontSize: 20 }}
              onChange={this.onChangePlaylistName}
              onBlur={this.updatePlaylistName}
            />
            <span className="playlist-owner">- {owner ? owner.name : ''}</span>
          </div>
          <div className="add-remove-buttons">
            <Button className="add-asset-button" onClick={this.handleAddAsset}>
              Add Assets
            </Button>
            <Button
              className={
                'remove-asset-button' +
                (Object.keys(this.state.selectedAssets).length === 0
                  ? ' disabled'
                  : '')
              }
              onClick={this.handleRemoveAsset}
              disabled={Object.keys(this.state.selectedAssets).length === 0}
            >
              Remove Assets
            </Button>
          </div>
          <AddAssetModal
            onHide={() => {
              this.setState({
                addingAssets: !this.state.addingAssets,
              })
            }}
            show={this.state.addingAssets}
            playlist={playlist}
          />
          <Alert
            variant="warning"
            className={
              (this.state.playlist.orientationConflict ? '' : 'hidden ') +
              'orientation-warning'
            }
          >
            <strong>
              This Playlist Contains Vertical and Horizontal Assets
            </strong>
            <br />
            Vertical assets will only display on vertical screens, and
            horizontal assets will only display on horizontal screens.
          </Alert>
          {/* condensed */}
          <Table className="edit-playlist-table" borderless striped responsive>
            <thead>
              <tr>
                <th className="checkbox-header">
                  <Form.Check
                    checked={this.allAssetsChecked()}
                    onChange={this.checkAll}
                  />
                </th>
                <th>Preview</th>
                <th>Title</th>
                <th>Duration</th>
                <th>Enabled</th>
              </tr>
            </thead>
            <tbody>
              {playlist.showtimesByIndex.map((showtime, i) => {
                const asset = showtime.asset
                if (asset) {
                  return (
                    <ShowtimeRow
                      key={asset.id}
                      index={i}
                      asset={asset}
                      showtime={showtime}
                      selected={asset.id in this.state.selectedAssets}
                      onSelect={() => this.checkAsset(asset)}
                      onSelectMinute={(event) =>
                        this.selectMinute(showtime, event)
                      }
                      onSelectSecond={(event) =>
                        this.selectSecond(showtime, event)
                      }
                      onToggleEnabled={() =>
                        this.handleEnabledChanged(showtime)
                      }
                      onDrag={this.onDrag}
                      onDragOver={this.onDragOver}
                      onDrop={this.onDrop}
                      dragClass={this.state.dragOver === i ? true : false}
                    />
                  )
                } else {
                  // This avoids a crash by not rendering the missing asset
                  return null
                }
              })}
              <tr
                onDrop={(event) => {
                  this.onDrop()
                }}
                onDragOver={this.onDragOverEndOfList}
                onDragLeave={this.onDragLeaveEndOfList}
                className={'eol ' + this.state.eolClass}
              >
                <td colSpan={7} />
              </tr>
            </tbody>
          </Table>
        </div>
      )
    } else {
      return this.state.error ? <NotFound /> : null
    }
  }
}

export default EditPlaylist
