import {computed, IObservableArray, observable, ObservableMap} from 'mobx'
import {AxiosResponse} from 'axios'

import API from 'api'
import contentStore from './contentStore'
import userStore from './userStore'
import screenStore from './screenStore'
import showtimeStore from './showtimeStore'
import User from './User'
import Asset from './Asset'
import Playlist from './Playlist'

export class PlaylistStore {
  @observable playlistsMap: ObservableMap<string, Playlist> = observable.map()
  @observable myPlaylistIDs: IObservableArray<string> = observable.array()
  @observable sharedPlaylistIDs: IObservableArray<string> = observable.array()
  @observable editablePlaylistIDs: IObservableArray<string> = observable.array()

  fetchInProgress: Set<string> = new Set()

  @computed
  get playlists(): Playlist[] {
    return Array.from(this.playlistsMap.values())
  }

  @computed
  get myPlaylists(): Playlist[] {
    return this.myPlaylistIDs
      .map(id => this.playlistsMap.get(id))
      .filter(Boolean)
      .reverse()
  }

  @computed
  get myAndSharedPlaylists(): Playlist[] {
    return this.sharedPlaylistIDs
      .map(id => this.playlistsMap.get(id))
      .filter(Boolean)
      .reverse()
  }

  @computed
  get sharedPlaylists(): Playlist[] {
    return this.myAndSharedPlaylists.filter(
      plst => this.myPlaylistIDs.indexOf(plst.id) === -1
    )
  }

  @computed
  get editablePlaylists(): Playlist[] {
    return this.editablePlaylistIDs
      .map(id => this.playlistsMap.get(id))
      .filter(Boolean)
  }

  /* Add/update playlist to the store */
  add = (playlist: Playlist): void => {
    this.playlistsMap.set(playlist.id, playlist)
  }

  get = (id: string): Playlist => {
    if (!id) throw new Error(`[PlaylistStore.get] Invalid id: ${id}`)

    return this.playlistsMap.get(id)
  }

  /* Add IFF playlist not already in the store. Returns true if new. */
  addIfNew = (playlist: Playlist): boolean => {
    if (this.playlistsMap.has(playlist.id)) {
      return false
    } else {
      this.add(playlist)
      return true
    }
  }

  /* When a user gives ownership of a playlist to someone else, remove from user's owned playlists */
  handoffOwner = (playlistID: string) => this.myPlaylistIDs.remove(playlistID)

  /* Remove playlist from the store */
  remove = (playlist: Playlist): void => {
    const id = playlist.id
    this.playlistsMap.delete(id)
    this.myPlaylistIDs.remove(id)
    this.sharedPlaylistIDs.remove(id)
    this.editablePlaylistIDs.remove(id)
    showtimeStore.removePlaylist(id)
    screenStore.removePlaylist(id)
  }

  fetch = (id: string): Promise<Playlist> => {
    if (!id) throw new Error(`[PlaylistStore.fetch] Invalid id: ${id}`)

    return new Promise((resolve, reject) => {
      const playlist = this.get(id)

      if (playlist) return resolve(playlist)

      API.playlists
        .get(id)
        .then(resource => {
          const playlistFromResource = Playlist.fromResource(resource)
          this.add(playlistFromResource)

          return playlistFromResource
        })
        .then(resolve)
        .catch(reject)
    })
  }

  /* Return the corresponding playlist from the store, or request from
      the backend and return null */
  findById = (id: string): Playlist | null => {
    if (!id) throw new Error(`[PlaylistStore.findById] Invalid id: ${id}`)

    const item = this.playlistsMap.get(id)
    if (item) return item
    else {
      if (!this.fetchInProgress.has(id)) {
        this.fetchInProgress.add(id)
        this.fetchByID(id)
      }
      return null
    }
  }

  playlistReducer = (map: {}, playlist) => {
    map[playlist.id] = playlist
    return map
  }

  /* Request the playlist from the backend and add it to the store */
  fetchByID = (id: string | number): Promise<Playlist> => {
    if (!id) {
      throw new Error(`[PlaylistStore.fetchByID] Invalid id: ${id}`)
    }

    return API.playlists
      .get(id)
      .then(data => {
        const playlist = Playlist.fromResource(data)
        this.add(playlist)
        this.fetchInProgress.delete(playlist.id)
        return playlist
      })
      .catch(data => {
        this.fetchInProgress.delete(id.toString())
        console.error(`PlaylistStore.fetchByID( ${id} ) failed:`, data)
        return null
      })
  }

  /* Fetch playlists from `/api/users/current/playlists` and updates
      this.myPlaylistIDs and this.sharedPlaylistIDs */
  fetchShared = (): void => {
    API.current.playlists().then(data => {
      const mine = []
      const shared = []
      const editable = []
      const myid = userStore.currentUser.id
      data.forEach(playlistR => {
        const playlist = Playlist.fromResource(playlistR)
        this.add(playlist)
        playlist.userPermissions.setPermission(
          playlistR.pivot.user_id,
          playlistR.pivot.permission
        )

        shared.push(playlist.id)
        if (playlist.ownerID === myid) mine.push(playlist.id)
        if (playlistR.pivot.permission !== 'viewer') editable.push(playlist.id)

        playlistR.assets.forEach(astR => {
          contentStore.add(Asset.fromResource(astR))
        })
        playlistR.users.forEach(usrR => {
          userStore.add(User.fromResource(usrR))
          playlist.userPermissions.setPermission(
            usrR.pivot.user_id,
            usrR.pivot.permission
          )
        })
      })
      this.myPlaylistIDs.replace(mine)
      this.sharedPlaylistIDs.replace(shared)
      this.editablePlaylistIDs.replace(editable)
    })
  }

  /* Register a newly created playlist with the backend */
  create = (playlist: Playlist): Promise<Playlist> => {
    const myid = userStore.currentUser.id
    return API.playlists
      .create(playlist.asResource())
      .then(resource => {
        const newPlaylist = Playlist.fromResource(resource)
        this.add(newPlaylist)
        if (newPlaylist.ownerID === myid) {
          this.myPlaylistIDs.push(newPlaylist.id)
          this.sharedPlaylistIDs.push(newPlaylist.id)
          this.editablePlaylistIDs.push(newPlaylist.id)
        } else if (newPlaylist.sharedIDs.indexOf(myid))
          this.sharedPlaylistIDs.push(newPlaylist.id)

        return newPlaylist
      })
      .catch(err => {
        console.error(err)
        throw new Error('Failed to create playlist')
      })
  }

  /* Delete a playlist from the backend, and call remove() to remove it from the store. */
  delete = (playlist: Playlist): Promise<AxiosResponse> => {
    showtimeStore.removePlaylist(playlist.id)
    return API.playlists
      .delete(playlist.asResource())
      .then((res: AxiosResponse) => {
        this.remove(playlist)
        return res
      })
      .catch(err => {
        console.error(err)
        let msg
        if (err.response) {
          if (err.response.data && err.response.data.status)
            msg = err.response.data.status
          else msg = err.response.statusText
        } else {
          msg = err
        }

        throw new Error(msg)
      })
  }
}

export default new PlaylistStore()
