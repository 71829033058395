/* tslint:disable:max-classes-per-file */

import * as React from 'react'
import {inject, observer} from 'mobx-react'
import {computed, observable} from 'mobx'
import {Accordion, Button, Card, Form, FormControl, FormLabel} from 'react-bootstrap'
import Select from 'react-select'
import Glyphicon from 'glyphicons'
import {nameToFnameLname, ValidationState} from 'globals'
import {AlertStore} from 'stores/alertStore'
import {RealInstitution, RealUser} from 'api/realsources'
import {RealInstitutionStore} from 'modules/admin/adminstores'
import {netValidity} from '../formhelpers'
import { ComputedValue } from 'mobx/lib/core/computedvalue'

// NOT RFC-5322 official standard, but is W3C standard
const EMAIL_RE = /^[a-z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-z0-9-]+(?:\.[a-z0-9-]+)*$/i
const USERNAME_RE = /^[a-z0-9](\.?[a-z0-9_-])+$/i

/*
 username
 name           `${fname} ${lname}`
 fname          name[0]
 lname          name[1:]
 email
 active         (default: 0)
 img_url
 broadcast_priv     (default: 0)
 is_admin       (default: 0)
 institution_id
 dept
 sys_admin
*/

interface Props {
  formType: 'create' | 'update'
  onSubmit: (user: RealUser, cb: (success: boolean) => void) => void
  id?: string
  className?: string
  reference?: RealUser
  disabled?: boolean
  alertStore?: AlertStore
}

interface State {
  disabled: boolean
  permPanelOpen: boolean
  instPanelOpen: boolean
}

function userFormPropsUnchanged(props, nextProps) {
  return ['formType', 'id', 'className', 'reference', 'disabled'].every(
    (prop) => {
      return props[prop] === nextProps[prop]
    }
  )
}

@inject('alertStore')
@observer
class UserForm extends React.Component<Props, State> {
  static defaultProps = {
    id: 'UserForm',
    className: '', // no default; UserForm is always applied
    reference: null,
    disabled: false,
  }

  static stateDefaults(props: Props): State {
    return {
      disabled: props.disabled,
      permPanelOpen: false,
      instPanelOpen: false,
    }
  }

  validDefaults = {
    name: 'warning' as 'warning',
    email: 'warning' as 'warning',
    username: 'warning' as 'warning',
    active: 'success' as 'success',
    broadcast_priv: 'success' as 'success',
    is_admin: 'success' as 'success',
    institution_id: 'warning' as 'warning',
    dept: 'warning' as 'warning',
  }

  problemsDefaults = {
    name: 'Name required',
    email: 'Email required',
    username: null,
    active: null,
    broadcast_priv: null,
    is_admin: null,
    institution_id: 'Institution required',
    dept: 'Department required',
  }

  inputRefs: {
    name: HTMLInputElement
    email: HTMLInputElement
    username: HTMLInputElement
    active: HTMLInputElement
    broadcast_priv: HTMLInputElement
    is_admin: HTMLInputElement
    dept: HTMLInputElement
  }

  values: {
    name: string
    email: string
    username: string
    active: boolean
    broadcast_priv: boolean
    is_admin: boolean
    institution: RealInstitution
    dept: string
  }

  valid: {
    name: ValidationState
    email: ValidationState
    username: ValidationState
    active: ValidationState
    broadcast_priv: ValidationState
    is_admin: ValidationState
    institution_id: ValidationState
    dept: ValidationState
  }

  problems: {
    name: string | null
    email: string | null
    username: string | null
    active: string | null
    broadcast_priv: string | null
    is_admin: string | null
    institution_id: string | null
    dept: string | null
  }

  constructor(props: Props) {
    super(props)

    if (!props.disabled && props.formType === 'update' && !props.reference)
      throw new Error(
        "UserForm requires prop 'reference' when prop 'formType' == \"update\" and !'disabled'"
      )

    this.inputRefs = observable.object({
      name: null,
      email: null,
      username: null,
      active: null,
      broadcast_priv: null,
      is_admin: null,
      dept: null,
    })

    this.valid = observable.object(this.validDefaults)
    this.values = observable.object(this.valuesDefault(props))
    this.problems = observable.object(this.problemsDefaults)
    this.state = UserForm.stateDefaults(props)
  }

  valuesDefault = (props: Props) => {
    return {
      name: this.getDefault('name', '', props),
      email: this.getDefault('email', '', props),
      username: this.getDefault('username', '', props),
      active: Boolean(this.getDefault('active', 1, props)),
      broadcast_priv: Boolean(this.getDefault('broadcast_priv', 0, props)),
      is_admin: Boolean(this.getDefault('is_admin', 0, props)),
      institution: RealInstitutionStore.get(
        this.getDefault('institution_id', null, props)
      ),
      dept: this.getDefault('dept', '', props),
    }
  }

  @computed
  get canSubmit(): boolean {
    const valids = [
      this.valid.name,
      this.valid.email,
      this.valid.username,
      this.valid.institution_id,
      this.valid.dept,
      this.valid.active,
      this.valid.broadcast_priv,
      this.valid.is_admin,
    ]

    return !this.state.disabled && netValidity(valids) === 'success'
  }

  @computed
  get statusOptionsValid(): ValidationState {
    return netValidity([
      this.valid.active,
      this.valid.broadcast_priv,
      this.valid.is_admin,
    ])
  }

  @computed
  validateName(): boolean {
    return this.valid.name == "success"
  }

  @computed
  validateEmail(): boolean {
    return this.valid.email == "success"
  }

  @computed
  validateUsername(): boolean {
    return this.valid.username == "success"
  }

  @computed
  validateDepartment(): boolean {
    return this.valid.dept == "success"
  }



  @computed
  get suggestedUsername(): string {
    if (this.values.email) {
      let uname = this.values.email
      if (this.values.email.includes('@'))
        uname = this.values.email.split('@', 1)[0]

      if (USERNAME_RE.test(uname)) return uname
    }
    return ''
  }

  @computed
  get suggestedUnameUsed(): boolean {
    return this.values.username === this.suggestedUsername
  }

  // UPDATE-TODO:
  // Move code with side effects to componentDidMount, and set initial state in the constructor.
  // UNSAFE_componentWillReceiveProps(nextProps: Props) {
  componentDidUpdate(nextProps: Props) {
    if (userFormPropsUnchanged(this.props, nextProps)) {
      return
    }

    if (
      !nextProps.disabled &&
      nextProps.formType === 'update' &&
      !nextProps.reference
    ) {
      throw new Error(
        "UserForm requires prop 'reference' when prop 'formType' == \"update\" and !'disabled'"
      )
    }

    this.reset(null, nextProps)
  }

  getDefault = (field: string, defaultValue: any = null, props?: Props) => {
    props = props || this.props

    if (props.reference) {
      return props.reference[field]
    }

    return defaultValue
  }

  onNameChange = (event?) => {
    const value = this.inputRefs.name.value
    let valid = null
    let problems = null

    this.values.name = value

    if (!value) {
      valid = 'warning'
      problems = 'Name required'
    } else if (
      !(
        this.props.formType === 'update' &&
        this.props.reference.name === value.trim()
      )
    ) {
      valid = 'success'
    }

    this.valid.name = valid
    this.problems.name = problems
  }

  onEmailChange = (event?) => {
    const value = this.inputRefs.email.value.trim()
    let valid = null
    let problems = null
    let updateUname = false

    if (value.includes('@')) {
      if (this.values.email.startsWith(`${this.values.username}@`)) {
        updateUname = true
      }
    } else if (this.values.email === this.values.username) {
      updateUname = true
    }

    this.values.email = value

    if (updateUname) {
      this.inputRefs.username.value = value.split('@', 1)[0]
      this.onUsernameChange()
    }

    if (!value) {
      valid = 'warning'
      problems = 'Email address required'
    } else if (!EMAIL_RE.test(value)) {
      valid = 'error'
      problems = 'Invalid email address'
    } else if (
      !(
        this.props.formType === 'update' && this.props.reference.email === value
      )
    ) {
      valid = 'success'
    }

    this.valid.email = valid
    this.problems.email = problems
  }

  onUsernameChange = (event?) => {
    const value = this.inputRefs.username.value.trim()
    let valid = null
    let problems = null

    this.values.username = value

    if (!value) {
      valid = 'warning'
      problems = 'Username required'
    } else if (!USERNAME_RE.test(value)) {
      valid = 'error'
      problems = 'Invalid username'
    } else if (
      !(
        this.props.formType === 'update' &&
        this.props.reference.username === value
      )
    ) {
      valid = 'success'
    }

    this.valid.username = valid
    this.problems.username = problems
  }

  onActiveChange = (event?) => {
    if (this.inputRefs.active) {
      const value = Boolean(this.inputRefs.active.checked)

      this.values.active = value

      this.valid.active =
        this.props.formType === 'update' &&
        Boolean(this.props.reference.active) === value
          ? null
          : 'success'
    }
  }

  onAlertPrivChange = (event?) => {
    if (this.inputRefs.broadcast_priv) {
      const value = Boolean(this.inputRefs.broadcast_priv.checked)

      this.values.broadcast_priv = value
      this.valid.broadcast_priv =
        this.props.formType === 'update' &&
        Boolean(this.props.reference.broadcast_priv) === value
          ? null
          : 'success'
    }
  }

  onIsAdminChange = (event?) => {
    if (this.inputRefs.is_admin) {
      const value = Boolean(this.inputRefs.is_admin.checked)

      this.values.is_admin = value
      this.valid.is_admin =
        this.props.formType === 'update' &&
        Boolean(this.props.reference.is_admin) === value
          ? null
          : 'success'
    }
  }

  onInstitutionChange = (value: RealInstitution) => {
    let valid = null
    let problems = null

    if (value && value instanceof Object) {
      if (
        !(
          this.props.formType === 'update' &&
          this.props.reference.institution_id === value.id
        )
      ) {
        valid = 'success'
      }
    } else {
      value = null
      valid = value ? 'error' : 'warning'
      problems = value ? 'Invalid institution' : 'Must select an institution'
    }

    this.values.institution = value
    this.valid.institution_id = valid
    this.problems.institution_id = problems
  }

  onDeptChange = (event?) => {
    let value = this.inputRefs.dept.value
    let valid = null
    let problems = null

    this.values.dept = value

    value = value.trim()
    if (!value) {
      valid = 'warning'
      problems = 'Department required'
    } else if (
      !(this.props.formType === 'update' && this.props.reference.dept === value)
    ) {
      valid = 'success'
    }

    this.valid.dept = valid
    this.problems.dept = problems
  }

  onSubmit = (event) => {
    event.preventDefault()

    if (!this.canSubmit) return

    try {
      this.setState({disabled: true})

      const [fname, lname] = nameToFnameLname(this.values.name.trim())

      const newUser: RealUser = {
        name: this.values.name.trim(),
        fname,
        lname,
        email: this.values.email.trim(),
        username: this.values.username.trim(),
        active: this.values.active ? 1 : 0,
        broadcast_priv: this.values.broadcast_priv ? 1 : 0,
        is_admin: this.values.is_admin ? 1 : 0,
        institution_id: this.values.institution.id,
        dept: this.values.dept.trim(),
      }

      if (this.props.formType === 'update') {
        newUser.id = this.props.reference.id
      }

      this.props.onSubmit(newUser, (success) => this.onSubmitCallback(success))
    } catch (err) {
      this.setState({disabled: false})
      this.props.alertStore.addAlert(
        err,
        'danger',
        'An error occurred while submitting'
      )
    }
  }

  onSubmitCallback = (success: boolean) => {
    this.reset(null)
  }

  togglePermPanel = () => {
    this.setState({permPanelOpen: !this.state.permPanelOpen})
  }

  reset = (event, props: Props = null) => {
    if (event) {
      event.preventDefault()
    }

    if (!props) {
      props = this.props
    }

    Object.assign(this.values, this.valuesDefault(props))
    this.setState(UserForm.stateDefaults(props), () => {
      if (props && props.reference) {
        this.onNameChange()
        this.onEmailChange()
        this.onUsernameChange()
        this.onActiveChange()
        this.onAlertPrivChange()
        this.onIsAdminChange()
        this.onInstitutionChange(
          RealInstitutionStore.get(props.reference.institution_id)
        )
        this.onDeptChange()
      } else {
        Object.assign(this.valid, this.validDefaults)
        Object.assign(this.problems, this.problemsDefaults)
      }
    })
  }


  render() {
    return (
      <form
        id={this.props.id}
        className={`UserForm ${this.props.className}`}
        onSubmit={this.onSubmit}
        noValidate
      >
        {/* NAME */}
        <Form.Group
          className="UserForm-name-group"
          controlId={`${this.props.id}-name`}
          // validationState={this.valid.name}
        >
          <FormLabel>Name</FormLabel>
          <FormControl
            type="text"
            value={this.values.name}
            // inputRef={(ref) => (this.inputRefs.name = ref)}
            // I think these will set the ::valid and ::invalid pseudotypes in css, so we may need to customize those to
            //    get this to work right?
            // Another option may be to use these validate functions to set the CSS class (i believe you can do this using bsPrefix?) and add an invalid CSS class to it
            isValid={this.validateName()}
            isInvalid={!this.validateName()}
            onChange={this.onNameChange}
            title={this.problems.name}
            required
            disabled={this.state.disabled}
            inputMode="text"
            placeholder="Joe Miner"
          />
          <FormControl.Feedback />
        </Form.Group>
        {/* EMAIL */}
        <Form.Group
          className="UserForm-email-group"
          controlId={`${this.props.id}-email`}
          // validationState={this.valid.email}
        >
          <FormLabel>E-Mail</FormLabel>
          <FormControl
            type="email"
            value={this.values.email}
            // inputRef={(ref) => (this.inputRefs.email = ref)}
            isValid={this.validateEmail()}
            isInvalid={!this.validateEmail()}
            onChange={this.onEmailChange}
            title={this.problems.email}
            required
            disabled={this.state.disabled}
            inputMode="email"
            placeholder="jminer@mst.edu"
          />
          <FormControl.Feedback />
        </Form.Group>
        {/* USERNAME */}
        <Form.Group
          className={
            'UserForm-username-group' +
            (this.suggestedUnameUsed ? ' username-from-email' : '')
          }
          controlId={`${this.props.id}-username`}
          // validationState={this.valid.username}
        >
          <FormLabel>Username</FormLabel>
          <FormControl
            type="text"
            value={this.values.username}
            // inputRef={(ref) => (this.inputRefs.username = ref)}
            isValid={this.validateUsername()}
            isInvalid={!this.validateUsername()}
            onChange={this.onUsernameChange}
            title={this.problems.username}
            required
            disabled={this.state.disabled}
            pattern={USERNAME_RE.source}
            inputMode="text"
            placeholder={this.suggestedUsername}
          />
          <FormControl.Feedback />
        </Form.Group>
        {/* INSTITUTION */}
        <Form.Group
          className="UserForm-institution-group"
          controlId={`${this.props.id}-institution`}
          // validationState={this.valid.institution_id}
        >
          <FormLabel title={this.problems.institution_id}>
            Institution
          </FormLabel>
          <InstitutionSelect
            value={this.values.institution}
            id={`${this.props.id}-institution`}
            onChange={(value) => this.onInstitutionChange(value)}
            title={this.problems.institution_id}
            disabled={this.state.disabled}
          />
        </Form.Group>
        {/* DEPARTMENT */}
        <Form.Group
          className="UserForm-dept-group"
          controlId={`${this.props.id}-dept`}
          // validationState={this.valid.dept}
        >
          <FormLabel title={this.problems.dept}>Department</FormLabel>
          <FormControl
            type="text"
            value={this.values.dept}
            // inputRef={(ref) => (this.inputRefs.dept = ref)}
            isValid={this.validateDepartment()}
            isInvalid={!this.validateDepartment()}
            onChange={this.onDeptChange}
            title={this.problems.dept}
            required
            disabled={this.state.disabled}
          />
          <FormControl.Feedback />
        </Form.Group>
        {/* Form.CheckES */}
        <Accordion>
          <Form.Group
            className="panel-label-fg"
            // validationState={this.statusOptionsValid}
          >
            <Accordion.Toggle as={FormLabel} className="panel-label" eventKey="8">
              StatusOptions&hellip;
            </Accordion.Toggle>
            {/* <FormLabel className="panel-label" onClick={this.togglePermPanel}>
              Status Options&hellip;
            </FormLabel> */}

          </Form.Group>
          {/* collapsible expanded={this.state.permPanelOpen} */}
          <Accordion.Collapse eventKey="8">
            <Card>
              <Form.Group
                className="UserForm-active-group"
                controlId={`${this.props.id}-active`}
                // validationState={this.valid.active}
              >
                <Form.Check
                  checked={this.values.active}
                  // inputRef={(ref) => (this.inputRefs.active = ref)}
                  id={`${this.props.id}-active`}
                  onChange={this.onActiveChange}
                  title={this.problems.active}
                  disabled={this.state.disabled}
                >
                  Active
                </Form.Check>
              </Form.Group>
              <Form.Group
                className="UserForm-broadcast_priv-group"
                controlId={`${this.props.id}-broadcast_priv`}
                // validationState={this.valid.broadcast_priv}
              >
                <Form.Check
                  checked={this.values.broadcast_priv}
                  // inputRef={(ref) => (this.inputRefs.broadcast_priv = ref)}
                  id={`${this.props.id}-broadcast_priv`}
                  onChange={this.onAlertPrivChange}
                  title={this.problems.broadcast_priv}
                  disabled={this.state.disabled}
                >
                  <Glyphicon glyph="bullhorn" />
                  Can Broadcast
                </Form.Check>
              </Form.Group>
              <Form.Group
                className="UserForm-is_admin-group"
                controlId={`${this.props.id}-is_admin`}
                // validationState={this.valid.is_admin}
              >
                <Form.Check
                  checked={this.values.is_admin}
                  // inputRef={(ref) => (this.inputRefs.is_admin = ref)}
                  id={`${this.props.id}-is_admin`}
                  onChange={this.onIsAdminChange}
                  title={this.problems.is_admin}
                  disabled={this.state.disabled}
                >
                  <Glyphicon glyph="sunglasses" />
                  Is Admin
                </Form.Check>
              </Form.Group>
            </Card>
          </Accordion.Collapse>
        </Accordion>
        <Button type="submit" disabled={!this.canSubmit} variant="primary">
          {this.props.formType === 'create' ? 'Create' : 'Update'} User
        </Button>
        <Button type="button" onClick={this.reset}>
          Reset
        </Button>
      </form>
    )
  }
}

interface InstitutionSelectProps {
  value: RealInstitution
  onChange: (newValue: RealInstitution) => void
  id?: string
  disabled?: boolean
  title?: string
  name?: string
  resultLimit?: number
}

@observer
export class InstitutionSelect extends React.Component<InstitutionSelectProps> {
  static defaultProps = {
    id: 'InstitutionSelect',
    disabled: false,
    title: null,
    name: 'institution',
    resultlimit: 5,
  }

  render() {
    return (
      <Select
        {...this.props}
        getOptionValue={(opt) => String(opt.id)}
        getOptionLabel={(opt) => opt.name}
        isMulti={false}
        blurInputOnSelect={true}
        options={Array.from(RealInstitutionStore.values).slice()}
      />
    )
  }
}

export default UserForm
