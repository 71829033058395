import * as React from 'react'
import {computed} from 'mobx'
import {observer} from 'mobx-react'
import Glyphicon from 'glyphicons'

import {RealUser} from 'api/realsources'
import {RealInstitutionStore} from 'modules/admin/adminstores'

interface Props {
  user: RealUser
  onClick: () => void
}

@observer
class UsersIndexRow extends React.Component<Props> {
  @computed
  get institutionImg() {
    const id = this.props.user.institution_id
    const inst = RealInstitutionStore.get(id)

    if (inst) {
      return (
        <img className="institution-img" title={inst.name} src={inst.img_url} />
      )
    } else {
      return null
    }
  }

  render() {
    return (
      <tr data-userid={this.props.user.id} onClick={this.props.onClick}>
        <td>{this.props.user.id}</td>
        <td>{this.props.user.name}</td>
        <td>{this.props.user.email}</td>
        <td>
          {this.props.user.active ? (
            <Glyphicon title="Active" glyph="ok" />
          ) : (
            <Glyphicon title="Inactive" glyph="remove" />
          )}
          {this.props.user.is_admin ? (
            <Glyphicon title="Administrator" glyph="sunglasses" />
          ) : null}
          {this.props.user.broadcast_priv ? (
            <Glyphicon title="Can Broadcast" glyph="bullhorn" />
          ) : null}
        </td>
        <td>{this.institutionImg}</td>
      </tr>
    )
  }
}

export default UsersIndexRow
