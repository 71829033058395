import * as React from 'react'
import {inject, observer} from 'mobx-react'
import {computed} from 'mobx'
import {Button, Dropdown} from 'react-bootstrap'
import Select from 'react-select'
import {Props as SelectProps} from 'react-select'

import {PermissionLevel} from 'globals'
import {UserStore} from 'stores/userStore'
import User from 'stores/User'
import Screen from 'stores/Screen'
import Playlist from 'stores/Playlist'
import UserSearchValue from './UserSearchValue'

// define new Select
const UserSelect = Select as new (props: SelectProps<User>) => Select<User>

interface Props {
  target: Screen | Playlist
  sharedWith: User[]
  addUsers: (users: User[], permLevel: PermissionLevel) => Promise<void[]>
  resultLimit?: number
  userStore?: UserStore
}

interface State {
  isLoading: boolean
  selected: User[]
  searchResults: User[]
  timeoutVar: NodeJS.Timer | number
  addingPermission: PermissionLevel
}

export interface SelectValueInfo {
  user: User
  target: Screen | Playlist
  removeUser: (user: User) => void
}

const RESULT_LIMIT = 5

const ADDING_PERMISSIONS: PermissionLevel[] = ['viewer', 'editor']

@inject('userStore')
@observer
class UserSearchBox extends React.Component<Props, State> {
  static defaultProps = {
    resultLimit: RESULT_LIMIT,
  }

  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      selected: [],
      searchResults: [],
      timeoutVar: null,
      addingPermission: 'viewer',
    }
  }

  @computed
  get owner() {
    return this.props.userStore.findById(this.props.target.ownerID)
  }

  updateSelected = (selectedValues: User[]) =>
    this.setState({
      selected: selectedValues,
    })

  /* onChange(): called when sharedWith is changed by the share Select.
      Updates the observable sharedWith and alerts the parent object.
  */
  onChange = (newValues: User[]) => {
    const removed = this.props.sharedWith.map((usr) => usr.id)
    const added = []

    newValues.forEach((usr) => {
      const idx = removed.indexOf(usr.id)
      if (idx === -1) {
        added.push(usr.id)
      } else {
        removed.splice(idx, 1)
      }
    })

    this.updateSelected(newValues)
  }

  optionRenderer = (user: User) => <span>{user.name}</span>

  /* handleResults(): handle a list of user results returned from backend.
      Removes options already-selected, sorts results, truncates, then
      stores the results in userSearchStore
  */
  handleResults = (results: User[]) => {
    results = results.filter((user) => {
      return this.props.sharedWith.every((sUser) => user.id !== sUser.id)
    })

    results.sort((userA, userB) => {
      if (userA.name < userB.name) {
        return -1
      } else if (userA.name > userB.name) {
        return 1
      }

      return 0
    })

    if (results.length > this.props.resultLimit) {
      results.length = this.props.resultLimit
    }

    // this.state.userSearchStore.users.replace(results)
    this.setState({
      isLoading: false,
      searchResults: results,
    })
  }

  /* onInputChange(): handle input from the user. This function readies
      the userSearchStore for loading and calls search() after a delay.
  */
  onInputChange = (userquery: string) => {
    this.setState({isLoading: true})

    userquery = userquery.toLowerCase()
    if (userquery.trim().length < 2) {
      this.setState({
        isLoading: false,
        searchResults: [],
      })
      return
    }

    clearTimeout(this.state.timeoutVar as number)
    this.setState({timeoutVar: setTimeout(() => this.search(userquery), 100)})
  }

  /* search(): performs the actual search query to the backend */
  search = (query: string) =>
    this.props.userStore
      .search(query)
      .then((users) => this.handleResults(users))

  /*
  valueRenderer = (user: User): SelectValueInfo => ({
    user,
    target: this.props.target,
    removeUser: this.removeUser
  })
  */

  /* react-select valueRenderer. Packages important parts for UserSearchValue */
  valueRenderer = ({innerProps, data, children}) => (
    <UserSearchValue
      innerProps={innerProps}
      target={this.props.target}
      removeUser={this.removeUser}
      user={data}
    >
      {children}
    </UserSearchValue>
  )

  removeUser = (user: User) => {
    const updatedSelected = this.state.selected.slice()
    const index = updatedSelected.indexOf(user)

    if (index !== -1) {
      updatedSelected.splice(index, 1)
    }

    this.setState({
      selected: updatedSelected,
    })
  }

  getPermIcon = (permLevel: PermissionLevel) =>
    permLevel === 'viewer' ? (
      <span
        className="permission-selector-icon fa fa-eye"
        title="viewer"
      />
    ) : (
      <span
        className="permission-selector-icon fa fa-pencil"
        title="editor"
      />
    )

  getPermOption = (permLevel: PermissionLevel) => (
    <div className="permission-selector-option">
      {this.getPermIcon(permLevel)} {permLevel}
    </div>
  )

  changeAddingPermission = (index: any) =>
    // index actually comes through as a number, but Dropdown is being dumb
    this.setState({addingPermission: ADDING_PERMISSIONS[index]})

  addUsersToTarget = () =>
    this.props
      .addUsers(this.state.selected, this.state.addingPermission)
      .then(() => this.setState({selected: [], searchResults: []}))

  render() {
    return (
      <div className="user-search-box">
        <UserSelect
          className="user-search"
          classNamePrefix="react-select"
          isClearable={false}
          isLoading={this.state.isLoading}
          getOptionLabel={(opt) => opt.name}
          getOptionValue={(opt) => opt.name}
          isMulti={true}
          // onBlurResetsInput={false}
          onChange={this.onChange}
          // onCloseResetsInput={false}
          onInputChange={this.onInputChange}
          // optionRenderer={this.optionRenderer} (shouldn't need replacement)
          options={this.state.searchResults}
          placeholder="Add users..."
          menuShouldScrollIntoView={false}
          value={this.state.selected}
          components={{
            SingleValue: this.valueRenderer,
          }}
        />
        <Dropdown
          className="add-permission-selector"
          id="add-permission-selector"
          onSelect={this.changeAddingPermission}
        >
          <Dropdown.Toggle id="dropdown-basic">
            <div>
              {this.getPermIcon(this.state.addingPermission)}
              {/* <span className="fa fa-chevron-down"/> */}
            </div>       
          </Dropdown.Toggle>
          <Dropdown.Menu data-container="body">
            {ADDING_PERMISSIONS.map(
              (permLevel, index) =>
                permLevel !== this.state.addingPermission && (
                  <Dropdown.Item
                    as="li"
                    key={permLevel}
                    eventKey={String(index)}
                    title={permLevel}
                  >
                    {this.getPermOption(permLevel)}
                  </Dropdown.Item>
                )
            )}
          </Dropdown.Menu>
        </Dropdown>
        <Button className="add-btn" onClick={this.addUsersToTarget}>
          add
        </Button>
      </div>
    )
  }
}

export default UserSearchBox
