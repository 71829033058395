import * as React from 'react'
import {inject, observer} from 'mobx-react'
import {Card, Accordion} from 'react-bootstrap'
import * as RB from 'react-bootstrap'
import Select from 'react-select'

import {RealUser} from 'api/realsources'
import {AlertStore} from 'stores/alertStore'
import {RealUserStore} from 'modules/admin/adminstores'
import UserForm from './form'

interface Props {
  alertStore?: AlertStore
  Accord?: Accordion
}

interface State {
  user: RealUser
  disabled: boolean
}

@inject('alertStore')
@observer
class UsersUpdateTab extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      user: null,
      disabled: true,
    }
  }

  onSelect = (newValue: RealUser | RealUser[]) => {
    if (newValue instanceof Array)
    {
      this.setState({
        user: null,
        disabled: true,
      })
    }
    else
    {
      this.setState({
        user: newValue,
        disabled: false,
      })
      // should toggle the accordion when user selects a user
      RB.useAccordionToggle("0", () => {})
    }
  }

  onSubmit = (userSubmitted: RealUser, cb: (success: boolean) => void) => {
    const userID = this.state.user.id
    userSubmitted.id = userID

    RealUserStore.update(userSubmitted)
      .then((user) => {
        this.setState({
          user: RealUserStore.get(userID),
        })
        this.props.alertStore.addAlert(
          "Successfully updated user.\nIt's recommended to refresh the page.",
          'success',
          'User Admin Update'
        )

        cb(true)
      })
      .catch((err) => {
        this.props.alertStore.addAlert(err, 'danger', 'User Admin Update, e1')
        cb(false)
      })
  }

  render() {
    return (
      <div id="user-update" className="admin-inner-tab">
        <span className="description">
          Use this form to update an existing user.
        </span>
        {/* <Select
          value={this.state.user}
          options={RealUserStore.values}
          className="user-update-select"
          getOptionLabel={(opt) => opt.name}
          getOptionValue={(opt) => String(opt.id)}
          isMulti={false}
          blurInputOnSelect={true}
          onChange={this.onSelect}
        /> */}
        {/* collapsible */}
        <Accordion>
          <Card>
            <Card.Header>
              <Select
                value={this.state.user}
                options={Array.from(RealUserStore.values)}
                className="user-update-select"
                getOptionLabel={(opt) => opt.name}
                getOptionValue={(opt) => String(opt.id)}
                isMulti={false}
                blurInputOnSelect={true}
                onChange={this.onSelect}
              />
            </Card.Header>
            <Card.Body>
              <Accordion.Collapse eventKey="0">
                <UserForm
                  id="user-updateform"
                  formType="update"
                  onSubmit={(user, callback) => this.onSubmit(user, callback)}
                  disabled={this.state.disabled}
                  reference={this.state.user}
                />
              </Accordion.Collapse>
            </Card.Body>
          </Card>
        </Accordion>
      </div>
    )
  }
}

export default UsersUpdateTab
