import * as React from 'react'
import {inject, observer} from 'mobx-react'
import {Accordion, Card, useAccordionToggle} from 'react-bootstrap'
import Select from 'react-select'

import {DataResponse} from 'api'
import {RealScreen, RealUser} from 'api/realsources'
import {AlertStore} from 'stores/alertStore'
import {RealScreenStore} from 'modules/admin/adminstores'
import ScreenForm, {PermedRealScreen} from './form'

interface Props {
  alertStore?: AlertStore
}

interface State {
  screen: RealScreen
  disabled: boolean
}

@inject('alertStore')
@observer
class ScreensUpdateTab extends React.Component<Props, State> {
  accordionToggle: React.RefObject<HTMLButtonElement>
  constructor(props) {
    super(props)

    this.accordionToggle = React.createRef()
    this.state = {
      screen: null,
      disabled: true,
    }
  }

  onSelect = (newValue: RealScreen | RealScreen[]) => {
    const shouldSwap = this.state.screen == null

    if (newValue instanceof Array)
      this.setState({
        screen: null,
        disabled: true,
      })
    else
    {
      this.setState({
        screen: newValue,
        disabled: false,
      })
      if (shouldSwap)
        this.accordionToggle.current.click()
    }
  }

  singlePromiseCheck = (resp) => {
    if (
      resp.status === 'success' ||
      resp.status === 200 ||
      resp.status === 201
    ) {
      return true
    } else if (
      resp.config &&
      resp.config.method === 'delete' &&
      resp.status === 204
    ) {
      return true
    }

    console.warn('singlePromiseCheck bad:', resp)
    return false
  }

  updatePermDiffs = (
    editorsDiff,
    viewersDiff,
    real: RealScreen
  ): Promise<boolean> => {
    const removePromises = []
    editorsDiff.removed.forEach((usrID) =>
      removePromises.push(
        RealScreenStore.deleteRelation(real.id, 'users', usrID)
      )
    )
    viewersDiff.removed.forEach((usrID) =>
      removePromises.push(
        RealScreenStore.deleteRelation(real.id, 'users', usrID)
      )
    )

    return Promise.all(removePromises).then((resolvedRemoves) => {
      if (resolvedRemoves.map(this.singlePromiseCheck).every(Boolean)) {
        // successful removals
        const addPromises = []
        editorsDiff.added.forEach((usrID) =>
          addPromises.push(
            RealScreenStore.createRelation(real.id, 'users', usrID, {
              permission: 'editor',
            })
          )
        )
        viewersDiff.added.forEach((usrID) =>
          addPromises.push(
            RealScreenStore.createRelation(real.id, 'users', usrID, {
              permission: 'viewer',
            })
          )
        )
        return Promise.all(addPromises).then((resolvedAdds) => {
          if (resolvedAdds.map(this.singlePromiseCheck).every(Boolean))
            return true
          throw new Error('Failed to add some permissions')
        })
      } else {
        throw new Error('Failed to remove some permissions')
      }
    })
  }

  onSubmit = (
    screenSubmitted: PermedRealScreen,
    cb: (success: boolean) => void
  ) => {
    const SCREEN_ID = this.state.screen.id
    screenSubmitted.id = SCREEN_ID

    const editorsDiff = screenSubmitted.editors
    const viewersDiff = screenSubmitted.viewers

    const newOwner = screenSubmitted.owner
    const origOwner = this.state.screen.owner
    const OwnerChanged = !origOwner || newOwner.id !== origOwner.id

    let origOwnerPerms = null
    let newOwnerPerms = null

    if (OwnerChanged) {
      newOwnerPerms = {
        screen_id: SCREEN_ID,
        user_id: newOwner.id,
        permission: 'owner',
      }

      if (origOwner) {
        origOwnerPerms = {
          screen_id: SCREEN_ID,
          user_id: origOwner.id,
          permission: 'editor',
        }
      }
    }

    const newOwnerEndpoint =
      this.state.screen.sharedIDs.indexOf(newOwner.id) !== -1
        ? RealScreenStore.updateRelation
        : RealScreenStore.createRelation

    RealScreenStore.update(screenSubmitted)
      .then((screen) => {
        if (OwnerChanged) {
          // change owners if different
          newOwnerEndpoint(
            screen.id,
            'users',
            newOwnerPerms.user_id,
            newOwnerPerms
          )
            .then(() => {
              if (origOwner) {
                RealScreenStore.updateRelation(
                  screen.id,
                  'users',
                  origOwnerPerms.user_id,
                  origOwnerPerms
                )
                  .then((userRes: DataResponse<RealUser>) => {
                    screen.owner = userRes.data

                    this.updatePermDiffs(editorsDiff, viewersDiff, screen)
                      .then((success) => {
                        this.setState({
                          screen: RealScreenStore.get(SCREEN_ID),
                        })
                        this.props.alertStore.addAlert(
                          "Successfully updated screen and ownership.\nIt's recommended to refresh the page.",
                          'success',
                          'Screen Admin Update, s1'
                        )
                        cb(true)
                      })
                      .catch((err) => {
                        console.error(err)
                        this.props.alertStore.addAlert(
                          '(Partial) failure to update shared permissions.',
                          'danger',
                          'Screen Admin Update, e1'
                        )
                        cb(false)
                      })
                  })
                  .catch((err) => {
                    console.error(err)
                    this.props.alertStore.addAlert(
                      err,
                      'danger',
                      'Screen Admin Update, e2'
                    )
                    cb(false)
                  })
              } else {
                // no original owner
                screen.owner = newOwner

                this.updatePermDiffs(editorsDiff, viewersDiff, screen)
                  .then((success) => {
                    this.setState({
                      screen: RealScreenStore.get(SCREEN_ID),
                    })
                    this.props.alertStore.addAlert(
                      "Successfully updated screen and ownership.\nIt's recommended to refresh the page.",
                      'success',
                      'Screen Admin Update, s2'
                    )
                    cb(true)
                  })
                  .catch((err) => {
                    console.error(err)
                    this.props.alertStore.addAlert(
                      '(Partial) failure to update shared permissions.',
                      'danger',
                      'Screen Admin Update, e3'
                    )
                    cb(false)
                  })
              }
            })
            .catch((err) => {
              console.error(err)
              this.props.alertStore.addAlert(
                err,
                'danger',
                'Screen Admin Update, e4'
              )
              cb(false)
            })
        } else {
          this.updatePermDiffs(editorsDiff, viewersDiff, screen)
            .then((success) => {
              this.setState({
                screen: RealScreenStore.get(SCREEN_ID),
              })
              this.props.alertStore.addAlert(
                "Successfully updated screen.\nIt's recommended to refresh the page.",
                'success',
                'Screen Admin Update, s3'
              )
              cb(true)
            })
            .catch((err) => {
              console.error(err)
              this.props.alertStore.addAlert(
                '(Partial) failure to update shared permissions: ' +
                  String(err),
                'danger',
                'Screen Admin Update, e5'
              )
              cb(false)
            })
        }
      })
      .catch((err) => {
        console.error(err)
        this.props.alertStore.addAlert(err, 'danger', 'Screen Admin Update, e6')
        cb(false)
      })
  }

  render() {

    return (
      <div id="screen-update" className="admin-inner-tab">
        <span className="description">
          Use this form to update an existing screen.
        </span>
        <Select
          value={this.state.screen}
          options={Array.from(RealScreenStore.map.values())}
          className="screen-update-select"
          getOptionLabel={opt => opt.name}
          getOptionValue={opt => String(opt.id)}
          isMulti={false}
          blurInputOnSelect={true}
          onChange={this.onSelect}
        />


        <Accordion>
          <Accordion.Toggle eventKey="7">
            <button style={{display: "none"}} ref={this.accordionToggle}/> 
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="7">
            <Card id="screen-update-body">
              <ScreenForm
                id="screen-updateform"
                formType="update"
                onSubmit={(screenSubmitted, callback) =>
                  this.onSubmit(screenSubmitted, callback)
                }
                disabled={this.state.disabled}
                reference={this.state.screen}
                />
            </Card>
            </Accordion.Collapse>
        </Accordion>
      </div>
    )
  }
}

export default ScreensUpdateTab
